.homepage-case-study-promo {
  position: relative;
}
.homepage-case-study-promo .full-bleed-wrapper {
  background-color: white;
}
.homepage-case-study-promo .link-block:hover .case-study-link__title {
  transform: scale(1.05);
  transition: 0.2s ease-in;
}
.homepage-case-study-promo .link-block:hover .link-text__arrow-wrap {
  transform: none;
  transition: all 0.2s;
}
.homepage-case-study-promo .case-study-link {
  position: absolute;
  bottom: 9%;
}
.homepage-case-study-promo .case-study-link.light .case-study-link__title,
.homepage-case-study-promo .case-study-link.light .case-study-link__description {
  color: white;
}
.homepage-case-study-promo .case-study-link.dark .case-study-link__title,
.homepage-case-study-promo .case-study-link.dark .case-study-link__description {
  color: black;
}

@media screen and (min-width: 0px) {
  .case-study-link {
    left: 6.5vw;
  }
}
@media screen and (min-width: 768px) {
  .case-study-link {
    left: 7.5vw;
  }
}
@media screen and (min-width: 1024px) {
  .case-study-link {
    left: 7vw;
  }
}